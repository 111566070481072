<template>
    <div id="product">
        <!-- 开头板块 -->
        <div class="home_bg w100">
            <img src="../assets/imgs/top-product1.jpg" alt="top-product.png" class="w100" />
            <Header></Header>
            <Culture>
                <h3 slot="title1">产品中心</h3>
                <p slot="title3">PRODUCT CENTER</p>
            </Culture>
        </div>

        <!-- PRODUCT -->
        <div id="product1" class="product w1200">
            <Title>
                <h5 slot="zh">产品名录</h5>
                <span slot="en">PRODUCT</span>
            </Title>
            <div class="product_t">
                <p>·<a @click="gto1()">智慧工会服务管理平台系统</a></p>
                <p>·<a @click="gto2()">工惠优选平台系统</a></p>
                <p>·<a @click="gto3()">工惠生活广场</a></p>
                <p>·<a @click="gto4()">激励服务管理平台系统</a></p>
                <p>·<a @click="gto6()">慈善募捐管理平台</a></p>
                <p>·<a>工惠通APP</a></p>
                <p>·<a @click="gto5()">企业后勤服务直通车</a></p>
                <!-- <p>·<span>工惠优选平台系统</span></p>
                <p>·<span>慈善募捐管理平台系统</span></p>
                <p>·<span>工惠通APP</span></p>
                <p>·<span>工会生活广场</span></p>
                <p>·<span>企业后勤服务直通车</span></p>
                <p>·<span>激励服务管理平台系统</span></p> -->
            </div>
        </div>
        <!-- system -->
        <div id="system" class="system w1200">
            <Title>
                <h5 slot="zh">智慧工会服务管理平台系统</h5>
                <span slot="en">to B 核心服务平台</span>
            </Title>
            <p>提供工会事务管理与会员服务的云平台，致力于打造工会领域全面融合的生态体系。</p>
            <div class="system_imgs">
                <div class="system_imgs_cont">
                    <img src="../assets/imgs/left-1.png" alt="left-1.png" class="system_imgs_left" />
                </div>
                <div class="system_imgs_cont">
                    <img src="../assets/imgs/right-1.png" alt="right-1.png" class="system_imgs_right" />
                </div>
            </div>
        </div>
        <!-- feature 1 -->
        <div class="feature w1200">
            <div class="feature_container">
                <div class="feature_l">
                    <div class="feature_l_cont">
                        <img src="../assets/imgs/imgs 6.png" alt="imgs 6.png" class="feature_imgs1" />
                    </div>
                </div>
                <div class="feature_r">
                    <div class="feature_t">
                        <Feature>
                            <p slot="circle_p">产品特色</p>
                            <p slot="p1">.</p>
                            <p slot="p2">.</p>
                            <p slot="p3">.</p>
                            <p slot="p4">.</p>
                            <span slot="sp1">针对会员的普惠服务平台</span>
                            <span slot="sp2">线上线下服务联动与资源整合能力</span>
                            <span slot="sp3">针对工会管理者的工会信息化管理平台</span>
                            <span slot="sp4">专业的工会网络工作团队</span>
                        </Feature>
                    </div>
                    <div class="feature_b">
                        <Feature>
                            <p slot="circle_p">功能模块</p>
                            <p slot="p1">.</p>
                            <p slot="p2">.</p>
                            <p slot="p3">.</p>
                            <p slot="p4">.</p>
                            <span slot="sp1">新闻资讯宣传平台：模块丰富、形式多样、有效互动</span>
                            <span slot="sp2">工会事务管理平台：工会管理100%全覆盖，使工会工作省时、省力、省心</span>
                            <span slot="sp3">职工普惠服务平台：提供各类暖心服务方式，线上线下结合的数字化云平台</span>
                            <span slot="sp4">工会升级服务平台：以工会实际需求为导向，提供定制化服务</span>
                        </Feature>
                    </div>
                </div>
            </div>
        </div>
        <!-- feature 2 -->
        <div id="feature2" class="feature w1200">
            <Title>
                <h5 slot="zh">工惠优选</h5>
                <span slot="en">普惠式消费服务平台，为企业会员提供灵活多样的福利选择</span>
            </Title>
            <div class="feature_container">
                <div class="feature_l">
                    <div class="feature_l_cont">
                        <img src="../assets/imgs/Gold.png" alt="Gold.png" class="feature_imgs1" />
                    </div>
                </div>
                <div class="feature_r">
                    <div class="feature_t">
                        <Feature>
                            <p slot="circle_p">产品特点</p>
                            <p slot="p1">.</p>
                            <p slot="p2">.</p>
                            <p slot="p3">.</p>
                            <span slot="sp1">升级员工慰问体验，让员工眼前一亮</span>
                            <span slot="sp2">提升慰问管理效能，统筹预算</span>
                            <span slot="sp3">构建更灵活的慰问体系</span>
                        </Feature>
                    </div>
                    <div class="feature_b">
                        <Feature>
                            <p slot="circle_p">运营服务</p>
                            <p slot="p1">.</p>
                            <p slot="p2">.</p>
                            <p slot="p3">.</p>
                            <span slot="sp1">20+品类支撑：精心打造匹配企业不同业务场景需求的慰问商品，满足企业多元化采购需求</span>
                            <span slot="sp2">第三方电商供应链：通过API将第三方电商平台数十万商品数据同步</span>
                            <span slot="sp3">功能完备：会员基本信息维护、商品选购、购物车结算、订单查询、积分管理、积分转增</span>
                        </Feature>
                    </div>
                </div>
            </div>
        </div>
        <!-- feature 3 -->
        <div id="feature3" class="feature3 w1200">
            <div class="feature3_backimgs">
                <img src="../assets/imgs/circle 14.png" alt="circle 14.png" />
            </div>
            <Title>
                <h5 slot="zh">工惠广场</h5>
                <span slot="en">工会普惠服务生活广场</span>
            </Title>
            <div class="feature3_t">
                <div class="feature3_bor"></div>
                <Feature>
                    <p slot="circle_p">产品特点</p>
                    <p slot="p1">.</p>
                    <p slot="p2">.</p>
                    <span slot="sp1">以标准系统为基础、趋向一对一定制化</span>
                    <span slot="sp2">基层工会组织独立使用，提升灵活性便捷性</span>
                </Feature>
            </div>
            <div>
                <Feature>
                    <p slot="circle_p">运营服务</p>
                    <p slot="p1">.</p>
                    <p slot="p2">.</p>
                    <p slot="p3">.</p>
                    <p slot="p4">.</p>
                    <p slot="p5">.</p>
                    <p slot="p6">.</p>
                    <span slot="sp1">主数据中心：OA系统数据共享</span>
                    <span slot="sp2">生活中心：提供便利生活服务</span>
                    <span slot="sp3">活动中心：图片、文字、视频等形式多样的展示手段，形成生动有效的互动</span>
                    <span slot="sp4">资讯中心：深度研读工会政策法规及上级工会精神，提供合法合规，精彩纷呈，专业便捷的工会全领域解决方案</span>
                    <span slot="sp5">普惠中心：工会福利种类繁多，形式多样，收发灵活</span>
                    <span slot="sp6">我的工会：便利清晰的信息显示和查询，问卷调查及评优投票</span>
                </Feature>
            </div>
        </div>
        <!-- feature 4 -->
        <div id="feature4" class="feature4 w1200">
            <div class="feature_container">
                <Title>
                    <h5 slot="zh">积分激励平台系统</h5>
                    <span slot="en">满足企业对员工即时激励，实现奖优罚劣的管理宗旨</span>
                </Title>
                <div class="feature_info">
                    <Feature>
                        <p slot="circle_p">功能模块</p>
                        <p slot="p1">.</p>
                        <p slot="p2">.</p>
                        <p slot="p3">.</p>
                        <p slot="p4">.</p>
                        <p slot="p5">.</p>
                        <span slot="sp1">积分充值：分为激励点数充值和消费积分充值</span>
                        <span slot="sp2">派发激励点数：将激励点数派发给指定的下级管理层或员工，用于奖励基层员工，直接消费使用</span>
                        <span slot="sp3">消费积分：上级可向下级直接奖励消费积分</span>
                        <span slot="sp4">工惠优选：奖励的消费积分用于工惠优选平台消费</span>
                        <span slot="sp5">数据分析：可定期统计点数派发情况、积分奖励情况、积分消费情况、用户活跃度等</span>
                    </Feature>
                </div>
                <!-- <div class="feature_r">
        </div> -->
            </div>
            <img src="../assets/imgs/Silver.png" alt="" class="feature_imgs1" />
        </div>
        <!-- feature 5 -->
        <div id="feature5" class="feature w1200">
            <Title>
                <h5 slot="zh">企业后勤服务直通车</h5>
                <span slot="en">LOGISTICS SERVICE</span>
            </Title>
            <div class="feature_container">
                <div class="feature_r">
                    <div class="feature_t">
                        <Feature>
                            <p slot="circle_p">产品特点</p>
                            <p slot="p1">.</p>
                            <p slot="p2">.</p>
                            <p slot="p3">.</p>
                            <p slot="p4">.</p>
                            <p slot="p5">.</p>
                            <span slot="sp1">合法化：实现系统用户的企业身份合法认证、用户账户的合规认证</span>
                            <span slot="sp2">共享化：实现商品主数据、组织架构主数据、成本中心主数据100%共享</span>
                            <span slot="sp3">规范化：实现劳保直采业务的整体迁移，PPE、工具直采业务功能上线，提升供应链业务的执行效能</span>
                            <span slot="sp4">集成化：与关联系统集成，实现业务流程线上化100%，消除重复地效工作</span>
                            <span slot="sp5">便捷化：以业务规则为依据，为一线职工、项目团队提供方便快捷的应用环境，提升客户体验</span>
                        </Feature>
                    </div>
                    <div class="feature_b">
                        <Feature>
                            <p slot="circle_p">产品收益</p>
                            <p slot="p1">.</p>
                            <p slot="p2">.</p>
                            <p slot="p3">.</p>
                            <span slot="sp1">效率提升：人员注册0损耗、PR审批减负、管理效率提升四倍</span>
                            <span slot="sp2">安全提升：全员统一身份认证、账户信息安全、流程执行安全</span>
                            <span slot="sp3">体验提升：单点登录、统一门户、直采生态</span>
                        </Feature>
                    </div>
                </div>
                <div class="feature_l1">
                    <img src="../assets/imgs/circle 13.png" alt="Gold.png" class="feature_imgs" />
                </div>
            </div>
        </div>
        <!-- feature 6 -->
        <div id="feature6" class="feature w1200">
            <Title>
                <h5 slot="zh">慈善募捐管理平台</h5>
                <span slot="en">Charitable donations</span>
            </Title>
            <div class="feature_container">
                <div class="feature_l">
                    <div class="feature_l_cont">
                        <img src="../assets/imgs/jijin_pic3.png" alt="Gold.png" class="feature_imgs1" />
                    </div>
                </div>
                <div class="feature_r">
                    <div class="feature_t">
                        <Feature>
                            <p slot="circle_p">功能架构</p>
                            <p slot="p1">.</p>
                            <p slot="p2">.</p>
                            <p slot="p3">.</p>
                            <p slot="p4">.</p>
                            <span slot="sp1">捐款项目维护：后台基本类别维护、专项基金维护、线下捐款信息录入</span>
                            <span slot="sp2">宣传内容管理：官方资讯发布、基金会介绍</span>
                            <span slot="sp3">会员中心：查看会员的捐款证书、感谢信、以及收据记录、维护和展示会员档案信息</span>
                            <span slot="sp4">数据统计：月度捐款统计、各专项基金捐赠统计、捐赠明细数据统计查询</span>
                        </Feature>
                    </div>
                    <!-- <div class="feature_b">
                        <Feature>
                            <p slot="circle_p">产品收益</p>
                            <p slot="p1">.</p>
                            <p slot="p2">.</p>
                            <p slot="p3">.</p>
                            <span slot="sp1">效率提升：人员注册0损耗、PR审批减负、管理效率提升四倍</span>
                            <span slot="sp2">安全提升：全员统一身份认证、账户信息安全、流程执行安全</span>
                            <span slot="sp3">体验提升：单点登录、统一门户、直采生态</span>
                        </Feature>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- Footer -->
        <Footer></Footer>
    </div>
</template>

<script>
import Culture from '../components/Culture'
import Header from '../components/Header'
import Title from '../components/Title'
import Feature from '../components/Feature'
import Footer from '../components/Footer/FootSec.vue'
export default {
  name: 'Product',
  components: {
    Header,
    Culture,
    Title,
    Feature,
    Footer
  },
  methods: {
    gto1 () {
      //   window.scrollTo(0, 650)
      var element = document.getElementById('system')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    },
    gto2 () {
      var element = document.getElementById('feature2')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    },
    gto3 () {
      var element = document.getElementById('feature3')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    },
    gto4 () {
      var element = document.getElementById('feature4')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    },
    gto5 () {
      var element = document.getElementById('feature5')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    },
    gto6 () {
      var element = document.getElementById('feature6')
      setTimeout(function () {
        element.scrollIntoView()
      }, 0)
    }
  }
}
</script>
<style scoped lang="less">
#product .system {
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #f2f2f2;
}
#product .system h3 {
    font-size: 40px;
    font-style: italic;
    color: #333333;
    line-height: 58px;
    font-weight: bold;
    margin: 30px 0 30px 80px;
}

.product {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 1rem;
    .product_t {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 1.3rem;
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
        min-width: 48%;
        p {
            width: 32%;
        }
        p:nth-child(1),
        p:nth-child(2),
        p:nth-child(3),
        p:nth-child(4),
        p:nth-child(5),
        p:nth-child(7) {
            a {
                cursor: pointer;
                border-bottom: 1px solid #666;
            }
        }
    }
    /deep/ .circle {
        display: none;
    }
    .feature_t {
        height: 270px;
        border-bottom: 1px solid #f2f2f2;
    }
}
li {
    min-width: 30% !important;
}
.system p {
    font-size: 1.25rem;
    color: #666666;
    line-height: 3rem;
}
.system .system_imgs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .system_imgs_cont {
        text-align: center;
    }
}
.system .system_imgs_left,
.system_imgs_right {
    width: 50%;
}

#product .feature {
    padding: 1rem 0 1rem 0;
    border-bottom: 1px solid #f2f2f2;
    overflow: hidden;
}
.feature .feature_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}
.feature_container .feature_imgs1 {
    width: 14rem;
}
.feature_container .feature_r {
    width: 1000px;
}
.feature_container .feature_t {
    height: 11.5rem;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;
}
#feature5 .feature_container .feature_t,
#feature6 .feature_container .feature_t {
    height: 19rem;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;
}
.feature_container .feature_b {
    height: 17rem;
    margin-top: 2rem;
}
#feature5 .feature_container .feature_b {
    height: 14rem;
    margin-top: 2rem;
}
.feature_container .feature_l1 .feature_imgs {
    // width: 100px;
    position: absolute;
    right: -20rem;
    bottom: 0px;
}

#feature2 .feature_container .feature_b {
    height: 13rem;
    margin-top: 2rem;
}

#product .feature3 {
    height: 33rem;
    padding: 1rem 0 5px 0;
    border-bottom: 1px solid #f2f2f2;
    position: relative;
    overflow: hidden;
}
#product .feature5 {
    border-bottom: 1px solid #f2f2f2;
    overflow: hidden;
}
.feature3 .feature3_backimgs {
    position: absolute;
    right: 0;
}
.feature3 .feature3_t {
    height: 18%;
    width: 100%;
    margin: 1rem 0 1rem 0;
    position: relative;
}
.feature3 .feature3_bor {
    border-right: 2px solid #f2f2f2;
    height: 3.125rem;
    position: absolute;
    right: 55%;
    top: 40%;
}
.feature3 .feature3_t li {
    width: 50%;
}

#product .feature4 {
    border-bottom: 1px solid #f2f2f2;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    margin: 1rem auto 0rem auto;
    overflow: hidden;
}
.feature4 img {
    height: 25rem;
    padding-right: 10rem;
}
.feature4 .feature_container {
    width: 1000px;
}
.feature_l {
    width: 30%;
}
.feature_container .feature_l_cont {
    text-align: center;
}
.feature4 .feature_info {
    margin-top: 20px;
    height: 20rem;
}
</style>
