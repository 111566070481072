<template>
    <div id="feature">
        <div class="circle_container">
            <div class="circle"></div>
            <slot name="circle_p">
                <p></p>
            </slot>
        </div>
        <ul>
            <li>
                <slot name="p1">
                    <p></p>
                </slot>
                <slot name="sp1"><span></span></slot>
            </li>
            <li>
                <slot name="p2">
                    <p></p>
                </slot>
                <slot name="sp2"><span></span></slot>
            </li>
            <li>
                <slot name="p3">
                    <p></p>
                </slot>
                <slot name="sp3"><span></span></slot>
            </li>
            <li>
                <slot name="p4">
                    <p></p>
                </slot>
                <slot name="sp4"><span></span></slot>
            </li>
            <li>
                <slot name="p5">
                    <p></p>
                </slot>
                <slot name="sp5"><span></span></slot>
            </li>
            <li>
                <slot name="p6">
                    <p></p>
                </slot>
                <slot name="sp6"><span></span></slot>
            </li>
            <li>
                <slot name="p7">
                    <p></p>
                </slot>
                <slot name="sp7"><span></span></slot>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  name: 'Fealure'
}
</script>

<style lang="less" scoped>
#feature {
    width: 100%;
    height: 100%;
    margin-left: 10px;
}
#feature ul li p {
    position: relative;
    top: -0.3125rem;
    margin-left: 0.3125rem;
    display: inline;
}
.circle_container {
    position: relative;
    margin-bottom: 0.625rem;
}
.circle {
    border: 0.25rem #a8ddff solid;
    border-radius: 50%;
    width: 0.9375rem;
    height: 0.9375rem;
    color: #a8ddff;
    opacity: 0.4;
    position: absolute;
}
.circle_container p {
    font-size: 1.5rem;
    font-weight: 400;
    font-style: italic;
    color: #333333;
    line-height: 3.125rem;
}
ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: calc(100% - 3.125rem);
}
li {
    font-size: 1.3rem;
    font-weight: 400;
    color: #666666;
    line-height: 3rem;
    min-width: 48%;
    /* 50% */
    // width: 50%;
}
span {
    margin-left: 0.3125rem;
}
</style>
